<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-08 10:59:40
-->
<template>
  <div id="content">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      layout="inline"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
    <div class="content-area-title">基础信息</div>
      <br/>
      <a-form-model-item label="图标">
        <div class="clearfix">
          <a-upload
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            :action="$consts.uploadFileUrl"
            :headers="$store.state.header"
            :before-upload="beforeUpload"
            @change="handleChange($event)"
          >
            <div v-if="fileList.length < 1">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          推荐比率840*470，支持jpg、png格式，大小不超过100k
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item></a-form-model-item>
      <a-form-model-item label="栏目" prop="idNavigation">
        <a-tree-select
          :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
          v-model="form.idNavigation"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          placeholder="请选择"
          tree-default-expand-all
        >
          <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
            Child Node1 {{ value }}
          </span>
        </a-tree-select>
      </a-form-model-item>

      <a-form-model-item label="作者" prop="author">
        <a-input placeholder="请输入" v-model="form.author" />
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title">
        <a-input placeholder="请输入" v-model="form.title" />
      </a-form-model-item>
      <a-form-model-item label="来源" prop="source">
        <a-input placeholder="请输入" v-model="form.source" />
      </a-form-model-item>
      <a-form-model-item label="副标题" prop="titleSub">
        <a-input placeholder="请输入" v-model="form.titleSub" />
      </a-form-model-item>
      <a-form-model-item label="是否置顶" prop="top">
        <a-radio-group v-model="form.top">
          <a-radio :value=0>
            是
          </a-radio>
          <a-radio :value=1>
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="摘要" prop="summary">
        <a-textarea v-model="form.summary" placeholder="请输入" :rows="1"/>
      </a-form-model-item>
      <a-form-model-item label="原创" prop="original">
        <a-radio-group v-model="form.original">
          <a-radio :value=0>
            是
          </a-radio>
          <a-radio :value=1>
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="简介" prop="intro">
        <a-input placeholder="请输入" v-model="form.intro" />
      </a-form-model-item>
      <a-form-model-item label="启用" prop="original">
        <a-radio-group v-model="form.releaseStatus">
          <a-radio :value=1>
            发布
          </a-radio>
          <a-radio :value=0>
            不发布
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="标签" prop="tagList">
        <a-select mode="tags" v-model="form.tagList" style="width: 100%" :token-separators="[',']">
        </a-select>
      </a-form-model-item>
      <br/>
      <br/>
      <div class="content-area-title">内容主体</div>
      <br/>

      <br/>
      <!-- <a-form-model-item label=" " :colon="false" prop="" :label-col="labelCol">
        asdfdf
      </a-form-model-item> -->

      <div>
        <div>
          <a-form-model-item label="添加视频" prop="mediaUrl" :wrapper-col="{ style:'width:500px;'}">
              <div style="width:500px">
                <a-upload-dragger
                  name="file"
                  :file-list="mvList"
                  :multiple="false"
                  :action="$consts.uploadFileUrl"
                  :headers="$store.state.header"
                  :before-upload="beforeUpload2"
                  @change="handleChange2($event)"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    点击或将图片拖拽到这里上传
                  </p>
                  <p class="ant-upload-hint">
                    支持扩展名：mp4
                  </p>
                </a-upload-dragger>
              </div>
          </a-form-model-item>
        </div>
      </div>

      <br/>
      <br/>
      <br/>
      <a-form-model-item style="padding-left:150px;">
        <a-button type="primary" @click="onSubmit">
          提交
        </a-button>
        <a-button style="margin-left: 10px;" @click="resetForm">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      mvList: [],
      // 下方是图片上传
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 上面是图片上传
      breadList: [
        {
          name: '内容管理',
          url: ''
        },
        {
          name: '发布视频',
          url: ''
        }
      ],
      treeData: [],
      labelCol: { style: 'width:150px;' },
      wrapperCol: { style: 'width:500px;' },
      wrapperCol2: { style: 'width:734px;' },
      other: '',
      form: {
        id: '',
        releaseStatus: 1,
        contentType: 'article',
        viewStyle: 'VS1',
        idNavigation: undefined,
        imageUrl: '',
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        author: '',
        title: '',
        source: '',
        titleSub: '',
        top: 0,
        summary: '',
        original: 0,
        intro: '',
        tagList: [],
        mediaUrl: '',

        contentConstitute: 'video',
        content: '',
        items: [],

        name: '',
        region: undefined,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
      }
    }
  },
  created () {
    if (this.$route.query.id) {
      this.getContentInfo()
    }
    this.queryTreeData()
  },
  computed: {
  },
  methods: {
    async getContentInfo () {
      const res = await this.$store.dispatch('http', {
        api: 'cmsManageContent',
        query: {
          id: this.$route.query.id
        }
      })
      console.log(res, 'rescontent1111111111')
      // this.form = { ...res }
      this.form = {
        id: res.id,
        releaseStatus: res.releaseStatus,
        contentType: res.contentType,
        viewStyle: res.viewStyle,
        idNavigation: res.idNavigation,
        imageUrl: '',
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        author: res.author,
        title: res.title,
        source: res.source,
        titleSub: res.titleSub,
        top: res.top,
        summary: res.summary,
        original: res.original,
        intro: res.intro,
        tagList: this.setTags(res),
        mediaUrl: res.mediaUrl,

        contentConstitute: res.contentConstitute,
        content: res.content,
        items: res.items
      }
      this.setImg(res)// 设置 图片回示
      this.form.items.reverse() // items返回的是倒序 要倒一下
      this.setMvList(res) // mvList is
    },
    setMvList (res) {
      if (res.mediaUrl) {
        this.mvList.push({ uid: '1', status: 'done', response: { result: res.mediaUrl }, url: res.mediaUrl, name: res.mediaUrl })
      }
    },
    setTags (res) {
      const tag = res.tags ? res.tags.split(',') : []
      return tag
    },
    setImg (res) {
      if (res.imageUrl && res.imageUrl.length > 0) this.fileList.push({ uid: '1', status: 'done', response: { result: res.imageUrl }, url: res.imageUrl, name: 'imgeUrl' })
      if (res.imageUrl2 && res.imageUrl2.length > 0) this.fileList.push({ uid: '2', status: 'done', response: { result: res.imageUrl2 }, url: res.imageUrl2, name: 'imgeUrl2' })
      if (res.imageUrl3 && res.imageUrl3.length > 0) this.fileList.push({ uid: '3', status: 'done', response: { result: res.imageUrl3 }, url: res.imageUrl3, name: 'imgeUrl3' })
      if (res.imageUrl4 && res.imageUrl4.length > 0) this.fileList.push({ uid: '4', status: 'done', response: { result: res.imageUrl4 }, url: res.imageUrl4, name: 'imgeUrl4' })
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 < 300
      setTimeout(() => {
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传图片格式为jpeg或者png的图片' })
          return false
        }
        if (!isLt2M) {
          this.$store.dispatch('showToast', { type: 'error', message: '图片大小不能超过300KB' })
        }
      }, 200)
      return isJpgOrPng && isLt2M
    },
    beforeUpload2 (file) {
      console.log(file)
      const isMp4 = file.type === 'video/mp4'
      // const isLt2M = file.size / 1024 < 300
      setTimeout(() => {
        if (!isMp4) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传Mp4格式的视频文件' })
          return false
        }
      }, 200)
      return isMp4
    },
    reSortItems () {
      // 重新排序 items 否则 添加新item 时 sort会错乱
      this.form.items.forEach((item, index) => {
        item.sort = index
      })
    },
    deleteItem (index) {
      this.form.items.splice(index, 1)
      // this.reSortItems()
    },
    moveItem ({ direction, index }) {
      console.log(index)
      let newTwoItems = []
      let items = this.form.items
      if (direction === 'up') {
        newTwoItems.push({ ...items[index] })
        newTwoItems.push({ ...items[index - 1] })
        items.splice(index - 1, 2, ...newTwoItems)
      } else {
        console.log('down')
        newTwoItems.push({ ...items[index + 1] })
        newTwoItems.push({ ...items[index] })
        items.splice(index, 2, ...newTwoItems)
      }
      this.reSortItems() // 重置修改序号
    },
    addItem (str) {
      this.form.items.push({
        resourceType: str,
        content: '',
        imageUrl: '',
        mediaUrl: '',
        sort: this.form.items.length + 1
      })
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'navagationTree',
        params: { navigationType: 'cms' }
      })
      this.treeData = res
    },
    // 图片上传 下方
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange (e) {
      console.log(e.fileList)
      this.fileList = e.fileList
    },
    handleChange3 (info, index) {
      console.log(index)
      const status = info.file.status
      if (status === 'uploading') {
        console.log(info.file, info.fileList, 'uploading')
      }
      if (status === 'done') {
        this.form.items[index].imageUrl = info.file.response.result
        this.$store.dispatch('showToast', { type: 'success', message: '上传成功' })
      } else if (status === 'error') {
        this.$store.dispatch('showToast', { type: 'error', message: '上传失败' })
      }
    },
    handleChange2 (info) {
      console.log(info, 'infoooooooo')
      let mvList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      mvList = mvList.slice(0, 1)

      // 2. read from response and show file link
      mvList = mvList.map(file => {
        if (file.response && file.response.result) {
          // Component will show file.url as link
          file.url = file.response.result
        }
        return file
      })
      console.log(mvList, 'mvList')
      this.mvList = mvList
      if (mvList[0] && mvList[0].url) {
        this.form.mediaUrl = mvList[0].url
      } else {
        this.form.mediaUrl = ''
      }
      // const status = info.file.status
      // if (status !== 'uploading') {
      //   console.log(111111111111111)
      //   console.log(info.file, info.fileList, 'uploading')
      // }
      // if (status === 'done') {
      //   console.log(22222222222222)
      //   this.form.mediaUrl = info.file.response.result
      //   this.$store.dispatch('showToast', { type: 'success', message: '上传成功' })
      // } if (status === 'removed') {
      //   console.log(33333333333)
      //   this.form.mediaUrl = ''
      //   this.mvList = []
      // } else if (status === 'error') {
      //   this.$store.dispatch('showToast', { type: 'error', message: '上传失败' })
      //   console.log(44444444)
      // }
      // console.log(5555555)
    },
    // 图处上传上方

    // 返回itemLabel
    itemLabel (resourceType) {
      switch (resourceType) {
        case 'text':
          return '文本内容'
        case 'title':
          return '标题'
        case 'img':
          return '图片'
        case 'mv':
          return '视频'
        default:
          return ' '
      }
    },
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.addContent()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async addContent () {
      this.fileList.forEach((item, index) => {
        if (index === 0) {
          this.form.imageUrl = item.response.result || item.url
        }
        if (index === 1) {
          this.form.imageUrl2 = item.response.result || item.url
        }
        if (index === 2) {
          this.form.imageUrl3 = item.response.result || item.url
        }
        if (index === 3) {
          this.form.imageUrl4 = item.response.result || item.url
        }
      })
      console.log(this.form)
      const res = await this.$store.dispatch('http', {
        api: 'addContent',
        params: this.form
      })
      if (res) {
        this.$store.dispatch('showToast', {
          type: 'success',
          message: '创建成功'
        })
      }
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.ruleForm.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
#content{
  max-width: 1400px;
  .content-title {
    font-size:16px;
    font-weight:bold
  }
  .content-area-title {
    font-size:16px;
    background:#fbfbfb;
    padding:10px;
  }
  .view-style-img-contain {
    display:flex;
    .view-style-img {
      width:150px;
      margin-right:7px;
    }
    .active {
      background:#eeeeee;
    }
  }
  .view-style-content-contain {
    display:flex;
    .view-style-img {
      text-align:center;
      width:150px;
      line-height:16px;
      margin-right:7px;
      padding:10px 0;
      div:first-child {
        font-weight: bold;
        font-size:16px;
      }
      div:last-child {
        padding-top:10px;
        color:#aaa;
      }
    }
    .active {
      border:3px solid #eeeeee;
    }
  }
}
</style>
